import { Input, Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, FluentProvider, ToggleButton, teamsLightTheme, Toaster, Toast, ToastBody, ToastTitle, useToastController } from '@fluentui/react-components'
import Header from '../../components/header'
import { DocumentCard } from '@fluentui/react'
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button'
import { SearchBox } from '@fluentui/react/lib/SearchBox'
import { Divider } from '@fluentui/react-components'
import { Archive24Filled } from '@fluentui/react-icons'
import { useEffect, useId, useState } from 'react'
import { useAppStateStore } from '../../store'
import { apiGet, apiPost } from '../../services/fetch/apiClient'
import { Breadcrumb } from '@fluentui/react/lib/Breadcrumb'
import _ from 'lodash'
import { logger } from '../../components/App'

export default function ManageGroup() {
    const [archive, setArchive] = useState(false)
    const [isHidden, setIsHidden] = useState(true)
    function ShowArchived() {
        setArchive(!archive)
        setIsHidden(!isHidden)
    }

    const [selectedGroups, setSelectedGroups] = useState<Group[]>([])
    const [archiveGroups, setArchiveGroups] = useState<Group[]>([])

    type Group = {
        id: string
        name: string
    }
    const inputId = useId()
    const appState = useAppStateStore()
    useEffect(() => {
        getGroups()
    }, [])

    const getGroups = async () => {
        try {
            const groupsResult = await apiGet<Group[]>('groups/get-active-groups', appState.identity.accessToken)
            setSelectedGroups(groupsResult)

            const archiveGroupsResult = await apiGet<Group[]>(
                'groups/get-archived-groups',
                appState.identity.accessToken
            )
            setArchiveGroups(archiveGroupsResult)
        } catch (err: any) {
            logger.debug(err)
        }
    }

    const [groupValue, setGroupValue] = useState('')
    function addGroup() {
        setGroup(groupValue)
        setGroupValue('')
        getGroups()
    }

    async function setGroup(groupName: string) {
        await apiPost('groups', appState.identity.accessToken, {
            name: groupName,
        })

        getGroups()
        notify("New", 'Interest Added successfully!')
    }

    async function deleteGroup(groupId: string) {
        console.log(groupId);
        if (groupId) {
            await apiPost('groups/delete-group', appState.identity.accessToken, { GroupId: groupId })
            getGroups()
            notify("Delete", 'Group deleted successfully!')
        }
    }

    async function archiveGroup(id: string, name: string) {
        await apiPost('groups/archive', appState.identity.accessToken, {
            GroupId: id,
        })

        notify("Archived", name + ' moved to archived list')
        getGroups()
    }

    const toasterId = useId()
    const { dispatchToast } = useToastController(toasterId)
    const notify = (title: string, message: string) =>
        dispatchToast(
            <Toast>
                <ToastTitle>{title}</ToastTitle>
                <ToastBody> {message}</ToastBody>
            </Toast>,
            { intent: 'success' }
        )

    return (
        <FluentProvider theme={teamsLightTheme}>
            <Toaster toasterId={toasterId} position="top-end" pauseOnHover pauseOnWindowBlur timeout={2000} />
            <div style={{ backgroundColor: '#EFF5F9' }}>
                <Header />

                <div style={{ marginLeft: '3rem', marginBottom: '1rem' }}>
                    <Breadcrumb
                        items={[
                            { text: 'Home', key: 'home', href: '/tab' },
                            { text: 'Preferences', key: 'preferences', href: '/#preference' },
                            { text: 'Manage Groups', key: 'manageGroups', isCurrentItem: true },
                        ]}
                    />
                </div>

                <DocumentCard
                    style={{
                        maxWidth: '100%',
                        height: '3.5rem',
                        padding: '1rem',
                        border: '1px solid #F9F9F9',
                        borderRadius: '0.5rem',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        gap: '1rem',
                        boxShadow: '0 3px 3px 0 rgba(0, 0, 0, .13)',
                        justifyContent: 'space-between',
                        marginLeft: '2.5rem',
                        marginRight: '2.5rem',
                    }}
                >
                    <div>
                        <Dialog>
                            <DialogTrigger disableButtonEnhancement>
                                <PrimaryButton style={{ backgroundColor: '#5b5fc7', color: 'white', border: '1px solid #5b5fc7' }} text="Add Group" />
                            </DialogTrigger>
                            <DialogSurface>
                                <DialogBody>
                                    <DialogTitle>Add Group</DialogTitle>
                                    <DialogContent>
                                        <Input
                                            placeholder="Group Name"
                                            type="text"
                                            id="message"
                                            name="message"
                                            value={groupValue}
                                            onChange={(e) => {
                                                setGroupValue(e.target.value)
                                            }}
                                            autoComplete="off"
                                        />
                                    </DialogContent>

                                    <DialogActions>
                                        <DialogTrigger disableButtonEnhancement>
                                            <Button appearance="primary" onClick={addGroup}>
                                                Add
                                            </Button>
                                        </DialogTrigger>
                                    </DialogActions>
                                </DialogBody>
                            </DialogSurface>
                        </Dialog>
                    </div>

                    <div style={{ display: 'inline-flex' }}>
                        <SearchBox
                            placeholder="search by group"
                            onEscape={(ev) => {
                                logger.debug('Custom onEscape Called')
                            }}
                            onClear={(ev) => {
                                logger.debug('Custom onClear Called')
                            }}
                            onChange={(_, newValue) => logger.debug('SearchBox onChange fired: ' + newValue)}
                            onSearch={(newValue) => logger.debug('SearchBox onSearch fired: ' + newValue)}
                        />
                    </div>
                </DocumentCard>

                <div style={{ marginLeft: '2.5rem', marginTop: '2rem' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span style={{ fontWeight: '600', paddingLeft: '0.8rem' }}>Active Groups</span>
                        <span style={{ fontWeight: '600', display: isHidden ? 'none' : 'block', paddingLeft: '9rem' }}>
                            Archived
                        </span>

                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <Archive24Filled />
                            <span style={{ paddingLeft: '0.5rem', paddingRight: '2.5rem', fontWeight: '600' }}>
                                <ToggleButton checked={archive} onClick={ShowArchived}>
                                    {!archive ? 'Show Archived' : 'Hide'}
                                </ToggleButton>
                            </span>
                        </span>
                    </div>
                    <div>
                        <table style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td valign="top" style={{ width: '50%' }}>
                                        <DocumentCard
                                            style={{
                                                width: archive ? '95%' : 'auto',
                                                maxWidth: '100%',
                                                height: 'auto',
                                                padding: '0.7rem',
                                                border: '1px solid #F9F9F9',
                                                borderRadius: '0.5rem',
                                                display: 'flex',

                                                flexDirection: 'column',
                                                boxShadow: '0 3px 3px 0 rgba(0, 0, 0, .13)',
                                                marginLeft: '0.3rem',
                                                marginRight: '2.5rem',
                                                marginTop: '0.5rem',
                                                gap: '10px',
                                            }}
                                        >
                                            {selectedGroups &&
                                                _.sortBy(selectedGroups, [(group) => group.name]).map(
                                                    (item: Group, index: number) => (
                                                        <>
                                                            <div>
                                                                <span style={{ fontWeight: '500' }}>{item.name}</span>
                                                                <small
                                                                    style={{
                                                                        color: '#616161',
                                                                        display: 'flex',
                                                                        justifyContent: 'flex-end',
                                                                        alignItems: 'center',
                                                                        paddingRight: '2rem',
                                                                    }}
                                                                >
                                                                    <span>
                                                                        <span>
                                                                            <Dialog>
                                                                                <DialogTrigger disableButtonEnhancement>
                                                                                    <DefaultButton style={{ padding: 0, marginLeft: 'auto' }}>Archive</DefaultButton>
                                                                                </DialogTrigger>
                                                                                <DialogSurface>
                                                                                    <DialogBody>
                                                                                        <DialogTitle>Archive</DialogTitle>
                                                                                        <DialogContent>
                                                                                            Are you sure you want to Archive {item.name}? once Archived cannot be
                                                                                            recovered.
                                                                                        </DialogContent>
                                                                                        <DialogActions>
                                                                                            <DialogTrigger disableButtonEnhancement>
                                                                                                <Button appearance="secondary">Cancel</Button>
                                                                                            </DialogTrigger>
                                                                                            <DialogTrigger disableButtonEnhancement>
                                                                                                <Button
                                                                                                    appearance="primary"
                                                                                                    onClick={() => archiveGroup(item.id, item.name)}
                                                                                                >
                                                                                                    Archive
                                                                                                </Button>
                                                                                            </DialogTrigger>
                                                                                        </DialogActions>
                                                                                    </DialogBody>
                                                                                </DialogSurface>
                                                                            </Dialog>
                                                                        </span>
                                                                        <> </>
                                                                        <span>
                                                                            <Dialog>
                                                                                <DialogTrigger disableButtonEnhancement>
                                                                                    <DefaultButton style={{ backgroundColor: '#ea3e3e', color: 'white' }} >Delete</DefaultButton>
                                                                                </DialogTrigger>
                                                                                <DialogSurface>
                                                                                    <DialogBody>
                                                                                        <DialogTitle>Delete</DialogTitle>
                                                                                        <DialogContent>
                                                                                            Are you sure you want to Delete this {item.name}?
                                                                                        </DialogContent>
                                                                                        <DialogActions>
                                                                                            <DialogTrigger disableButtonEnhancement>
                                                                                                <Button appearance="secondary">No</Button>
                                                                                            </DialogTrigger>
                                                                                            <DialogTrigger disableButtonEnhancement>
                                                                                                <Button
                                                                                                    style={{ backgroundColor: '#ea3e3e', color: 'white' }}
                                                                                                    onClick={() => deleteGroup(item.id)}
                                                                                                >
                                                                                                    Yes
                                                                                                </Button>
                                                                                            </DialogTrigger>
                                                                                        </DialogActions>
                                                                                    </DialogBody>
                                                                                </DialogSurface>
                                                                            </Dialog>
                                                                        </span>
                                                                    </span>
                                                                </small>
                                                            </div>
                                                            {selectedGroups.length - 1 != index && (
                                                                <Divider style={{ padding: '0.4rem' }} appearance="subtle"></Divider>
                                                            )}
                                                        </>
                                                    )
                                                )}
                                        </DocumentCard>
                                    </td>
                                    <td style={{ display: isHidden ? 'none' : 'block', width: '100%' }}>
                                        <DocumentCard
                                            style={{
                                                width: archive ? '95%' : 'auto',

                                                maxWidth: '100%',
                                                height: 'auto',
                                                padding: '0.7rem',
                                                border: '1px solid #F9F9F9',
                                                borderRadius: '0.5rem',
                                                display: 'flex',

                                                flexDirection: 'column',
                                                boxShadow: '0 3px 3px 0 rgba(0, 0, 0, .13)',
                                                marginLeft: '0.3rem',
                                                marginRight: '2.5rem',
                                                marginTop: '0.5rem',
                                                opacity: 0.4,
                                                gap: '10px',
                                            }}
                                        >
                                            {archiveGroups?.map((item: Group, index: number) => (
                                                <>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <span style={{ fontWeight: '500' }}>{item.name}</span>

                                                        <small
                                                            style={{
                                                                color: '#616161',
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                                paddingRight: '2rem',
                                                            }}
                                                        >
                                                            <span>Some additional context about this group goes here</span>
                                                        </small>
                                                    </div>
                                                    {archiveGroups.length - 1 != index && (
                                                        <Divider style={{ padding: '0.4rem' }} appearance="subtle"></Divider>
                                                    )}
                                                </>
                                            ))}
                                        </DocumentCard>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </FluentProvider>
    )
}