import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components'
import { LayoutGrid, BarChart3, ChevronUp, ChevronDown } from 'lucide-react';
import CardView from '../../components/Reports/CardView';
import GraphView from '../../components/Reports/GraphView';
import GlobalDownload from '../../components/Reports/GlobalDownload';
import SharedFilters from '../../components/Reports/filters/SharedFilters';
import Header from '../../components/header';
import { generateMeetingData } from '../../utils/dataGenerator';
import { Breadcrumb } from '@fluentui/react/lib/Breadcrumb';
import { apiGet } from '../../services/fetch/apiClient'
import { useAppStateStore } from '../../store'
import { IMeetingInfo } from '../../types';

function AttendanceReports() {
    const [view, setView] = useState<'cards' | 'graph'>('cards');
    //const [meetings, setMeetings] = useState<IMeetingInfo[]>([]);
    const [meetings, setMeetings] = useState(() => generateMeetingData(40));
    const appState = useAppStateStore()

    useEffect(() => {
        const fetchMeetings = async () => {
            try {
                const response = await apiGet<IMeetingInfo[]>('meetings', appState.identity.accessToken);
                setMeetings(response);
            } catch (error) {
                console.error('Error fetching meetings:', error);
            }
        };

        fetchMeetings();
    }, []);
    const [selectedTopics, setSelectedTopics] = useState<any[]>([]);
    const [selectedAttendees, setSelectedAttendees] = useState<any[]>([]);
    const [dateRange, setDateRange] = useState({ start: '', end: '' });
    const [showScrollButtons, setShowScrollButtons] = useState(false);
    const [isAtBottom, setIsAtBottom] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            // Show buttons when scrolled down more than 100px
            setShowScrollButtons(scrollTop > 100);
            // Check if we're at the bottom (with a 20px threshold)
            setIsAtBottom(scrollTop + windowHeight >= documentHeight - 20);
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Initial check
        handleScroll();

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
        });
    };

    const colors = [
        { bg: '#EEF2FF', text: '#4F46E5', chart: 'rgba(79, 70, 229, 0.8)' },
        { bg: '#F0F9FF', text: '#0369A1', chart: 'rgba(3, 105, 161, 0.8)' },
        { bg: '#FDF4FF', text: '#A21CAF', chart: 'rgba(162, 28, 175, 0.8)' },
        { bg: '#FFF1F2', text: '#BE123C', chart: 'rgba(190, 18, 60, 0.8)' },
        { bg: '#FEF3C7', text: '#92400E', chart: 'rgba(146, 64, 14, 0.8)' },
        { bg: '#F5F3FF', text: '#5B21B6', chart: 'rgba(91, 33, 182, 0.8)' },
        { bg: '#FFF7ED', text: '#9A3412', chart: 'rgba(154, 52, 18, 0.8)' },
        { bg: '#F0FDF4', text: '#166534', chart: 'rgba(22, 101, 52, 0.8)' }
    ];

    const topicOptions = Array.from(new Set(meetings.map(m => m.interestName)))
        .map((topic, index) => ({
            value: topic,
            label: topic,
            color: colors[index % colors.length]
        }));
    const attendeeOptions = meetings.reduce((acc, meeting) => {
        const topic = meeting.interestName;
        const color = colors[topicOptions.findIndex(t => t.value === topic) % colors.length];

        meeting.attendees.forEach(attendee => {
            const key = `${attendee.attendeeEmail}-${topic}`;
            if (!acc[topic]) {
                acc[topic] = {
                    label: topic,
                    options: []
                };
            }
            if (!acc[topic].options.some((opt: any) => opt.value === key)) {
                acc[topic].options.push({
                    value: key,
                    label: `${attendee.attendeeName} (${attendee.attendeeEmail})`,
                    email: attendee.attendeeEmail,
                    topic,
                    color
                });
            }
        });
        return acc;
    }, {} as any);
    /*const attendeeOptions = meetings.reduce((acc, meeting) => {
        const topic = meeting.interestName;
        const color = colors[topicOptions.findIndex(t => t.value === topic) % colors.length];

        meeting.attendees.forEach(attendee => {
            const key = `${attendee.attendeeEmail}-${topic}`;
            if (!acc[topic]) {
                acc[topic] = {
                    label: topic,
                    options: []
                };
            }
            if (!acc[topic].options.some((opt: any) => opt.value === key)) {
                acc[topic].options.push({
                    value: key,
                    label: `${attendee.attendeeName} (${attendee.attendeeEmail})`,
                    email: attendee.attendeeEmail,
                    topic,
                    color
                });
            }
        });
        return acc;
    }, {} as any);*/

    const hasActiveFilters: boolean = selectedTopics.length > 0 || selectedAttendees.length > 0 || dateRange.start !== '' || dateRange.end !== '';

    const clearAllFilters = () => {
        setSelectedTopics([]);
        setSelectedAttendees([]);
        setDateRange({ start: '', end: '' });
    };

    const filteredMeetings = meetings.filter(meeting => {
        if (selectedTopics.length && !selectedTopics.some(t => t.value === meeting.interestName)) {
            return false;
        }
        if (selectedAttendees.length && !meeting.attendees.some(a => selectedAttendees.some(sa => sa.email === a.attendeeEmail))) {
            return false;
        }
        if (dateRange.start) {
            const meetingDate = new Date(meeting.meetingStartDateTime);
            const startDate = new Date(dateRange.start);
            if (meetingDate < startDate) return false;
        }
        if (dateRange.end) {
            const meetingDate = new Date(meeting.meetingStartDateTime);
            const endDate = new Date(dateRange.end);
            if (meetingDate > endDate) return false;
        }
        return true;
    });

    return (
        <FluentProvider theme={teamsLightTheme}>
            <div className="min-h-screen p-4 sm:p-6 lg:p-8">
                <div className="app-container max-w-9xl mx-auto rounded-lg shadow-lg p-6">
                    {/* <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">
                        Attendance Report
                    </h1> */}
                    <div style={{ backgroundColor: '#EFF5F9' }}>
                        <Header />

                        <div className="ml-0 mb-4">
                            <Breadcrumb
                                items={[
                                    { text: 'Home', key: 'home', href: '#/tab' },
                                    { text: 'Preferences', key: 'preferences', href: '#/preference' },
                                    { text: 'Meetings Report', key: 'meetingsReport', isCurrentItem: true },
                                ]}
                            />
                        </div>
                    </div>

                    <div className="flex items-center justify-between gap-2 mb-6">
                        <div className="flex bg-gray-100 p-0.5 sm:p-1 rounded-lg flex-shrink-0">
                            <button
                                onClick={() => setView('cards')}
                                className={`flex items-center px-2 py-1 sm:px-4 sm:py-2 rounded-md text-xs sm:text-sm ${view === 'cards'
                                    ? 'bg-white text-blue-600 shadow'
                                    : 'text-gray-600 hover:text-gray-900'
                                    }`}
                            >
                                <LayoutGrid className="w-3.5 h-3.5 sm:w-5 sm:h-5 mr-1 sm:mr-2" />
                                <span className="whitespace-nowrap">Grid View</span>
                            </button>
                            <button
                                onClick={() => setView('graph')}
                                className={`flex items-center px-2 py-1 sm:px-4 sm:py-2 rounded-md text-xs sm:text-sm ${view === 'graph'
                                    ? 'bg-white text-blue-600 shadow'
                                    : 'text-gray-600 hover:text-gray-900'
                                    }`}
                            >
                                <BarChart3 className="w-3.5 h-3.5 sm:w-5 sm:h-5 mr-1 sm:mr-2" />
                                <span className="whitespace-nowrap">Graph View</span>
                            </button>
                        </div>
                        <GlobalDownload meetings={filteredMeetings} />
                    </div>

                    <SharedFilters
                        topicOptions={topicOptions}
                        selectedTopics={selectedTopics}
                        setSelectedTopics={setSelectedTopics}
                        attendeeOptions={Object.values(attendeeOptions)}
                        selectedAttendees={selectedAttendees}
                        setSelectedAttendees={setSelectedAttendees}
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        hasActiveFilters={hasActiveFilters}
                        clearAllFilters={clearAllFilters}
                    />

                    {/* Keep both views mounted but control visibility */}
                    <div className="relative">
                        <div style={{ display: view === 'cards' ? 'block' : 'none' }}>
                            <CardView meetings={filteredMeetings} />
                        </div>
                        <div style={{ display: view === 'graph' ? 'block' : 'none' }}>
                            <GraphView meetings={filteredMeetings} />
                        </div>
                    </div>
                </div>
                {/* Scroll buttons */}
                <div style={{ position: 'fixed', bottom: '24px', right: '24px', zIndex: 9999 }} className="flex flex-col gap-3">
                    {/* Scroll to top button */}
                    <motion.button
                        onClick={scrollToTop}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{
                            opacity: showScrollButtons ? 1 : 0,
                            y: showScrollButtons ? 0 : 10,
                        }}
                        transition={{ duration: 0.2 }}
                        className="flex items-center justify-center bg-white hover:bg-indigo-50 text-indigo-600 p-3 rounded-full shadow-lg hover:shadow-xl border border-indigo-100 group"
                        style={{
                            display: showScrollButtons ? 'flex' : 'none',
                            cursor: 'pointer',
                            transform: 'translateZ(0)'  // Force GPU acceleration
                        }}
                    >
                        <motion.div
                            className="relative"
                            whileHover={{ y: -2 }}
                            transition={{ duration: 0.2 }}
                        >
                            <ChevronUp
                                size={20}
                                className="text-indigo-600 transition-transform duration-200 group-hover:-translate-y-0.5"
                            />
                            <ChevronUp
                                size={20}
                                className="absolute top-0 left-0 opacity-0 transition-all duration-200 group-hover:opacity-50 group-hover:translate-y-0.5"
                            />
                        </motion.div>
                    </motion.button>

                    {/* Scroll to bottom button */}
                    <motion.button
                        onClick={scrollToBottom}
                        initial={{ opacity: 0, y: -10 }}
                        animate={{
                            opacity: !isAtBottom ? 1 : 0,
                            y: !isAtBottom ? 0 : -10,
                        }}
                        transition={{ duration: 0.2 }}
                        className="flex items-center justify-center bg-white hover:bg-indigo-50 text-indigo-600 p-3 rounded-full shadow-lg hover:shadow-xl border border-indigo-100 group"
                        style={{
                            display: !isAtBottom ? 'flex' : 'none',
                            cursor: 'pointer',
                            transform: 'translateZ(0)'  // Force GPU acceleration
                        }}
                    >
                        <motion.div
                            className="relative"
                            whileHover={{ y: 2 }}
                            transition={{ duration: 0.2 }}
                        >
                            <ChevronDown
                                size={20}
                                className="text-indigo-600 transition-transform duration-200 group-hover:translate-y-0.5"
                            />
                            <ChevronDown
                                size={20}
                                className="absolute top-0 left-0 opacity-0 transition-all duration-200 group-hover:opacity-50 group-hover:-translate-y-0.5"
                            />
                        </motion.div>
                    </motion.button>
                </div>
            </div>

        </FluentProvider>
    );
}
export default AttendanceReports;