/* eslint-disable jsx-a11y/alt-text */
import { Avatar } from '@fluentui/react-components'
import { initializeIcons } from '@fluentui/react'
import musicboy from '../assets/vector_sitting_on_couch.svg'
import toprightcurve from '../assets/toprightcurve.svg'
import stars from '../assets/vector_bg_objects.svg'
import { useAppStateStore } from '../store'
import { useEffect, useState } from 'react'

initializeIcons()

export interface IGreetingProps {
  name: string | undefined
  profilePic: string
}

export default function Header() {
  const appState = useAppStateStore()
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 700)

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 700)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div>
      <img
        src={stars}
        // style={{ position: 'absolute', right: '0', display: mobile ? 'none' : 'block' }}
        style={{ position: 'absolute', right: isLargeScreen ? '0' : '300' }}
        className={`animate__animated animate__fadeInLeftBig ${isLargeScreen ? 'ms-hiddenXxxl' : 'ms-hiddenXxlUp'}`}
      ></img>
      <div style={{ position: 'absolute', right: '0' }}>
        <img
          src={toprightcurve}
          width={250}
          className={`animate__animated animate__fadeInTopRight ${isLargeScreen ? 'ms-hiddenXxxl' : 'ms-hiddenXxlUp'}`}
          style={{ display: isLargeScreen ? 'block' : 'none' }}
        ></img>
      </div>
      <div
        style={{
          marginLeft: '2.5rem',
          display: 'flex',
          // flexDirection: 'row',
          flexDirection: isLargeScreen ? 'row' : 'column',
          gap: '2rem',
          paddingTop: '2.5rem',
          justifyContent: 'space-between',
        }}
        className={isLargeScreen ? 'ms-hiddenXxxl' : 'ms-hiddenXxlUp'}
      >
        <div>
          <div className="flex items-center">
            <div>
              <Avatar
                name={appState.identity.name}
                image={{
                  src: appState.profile.profilePicUrl,
                }}
                size={56}
              />
            </div>

            <div
              style={{ paddingLeft: '1rem', display: 'flex', flexDirection: 'column' }}
              className={isLargeScreen ? 'ms-hiddenXxxl' : 'ms-hiddenXxlUp'}
            >
              <span className="ms-fontWeight-semibold">Hello, {appState.identity.name}.</span>

              <span
                style={{ color: '#242424', display: isLargeScreen ? 'block' : 'none' }}
                className={isLargeScreen ? 'ms-hiddenXxxl' : 'ms-hiddenXxlUp'}
              >
                Choose your preferences and let's turn workplace connections
              </span>
              <span
                style={{ color: '#242424', display: isLargeScreen ? 'block' : 'none' }}
                className={isLargeScreen ? 'ms-hiddenXxxl' : 'ms-hiddenXxlUp'}
              >
                into fantastic moments by the Watercooler!
              </span>
            </div>
          </div>
        </div>

        <div style={{ paddingRight: '4rem', paddingLeft: '3rem' }}>
          <img src={musicboy} className=" animate__animated  animate__fadeInBottomLeft animate__fast" />
        </div>
      </div>
    </div>
  )
}
