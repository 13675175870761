import { Divider } from '@fluentui/react-components'
import { Settings24Regular, PeopleQueue24Regular, CircleSmallFilled, PeopleCommunity24Regular } from '@fluentui/react-icons'
import { Link } from 'react-router-dom'
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel, AccordionToggleEventHandler } from '@fluentui/react-components'
import React, { useEffect } from 'react'
import MeetingAttendees from './MeetingAttendees'
import { useAppStateStore } from '../../store'
import { apiGet } from '../../services/fetch/apiClient'
import { logger } from '../../components/App'

export interface MeetingSettingsModel {
  minAttendees: number
  maxAttendees: number
}

export default function AdminConfig() {
  const [openItems, setOpenItems] = React.useState(['1'])
  const handleToggle: AccordionToggleEventHandler<string> = (event, data) => {
    setOpenItems(data.openItems)
  }

  const appState = useAppStateStore()

  const getMeetingsettings = async () => {
    try {
      const meetingsettings = await apiGet<MeetingSettingsModel>(
        'company/meetingsettings',
        appState.identity.accessToken
      )
      appState.setMeetingAttendeeCount(meetingsettings.minAttendees, meetingsettings.maxAttendees)
    } catch (err: any) {
      logger.debug(err)
    }
  }

  useEffect(() => {
    getMeetingsettings()
  }, [])

  return (
    <div
      style={{
        fontSize: '15px',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '0.5rem',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Link to={'/manageInterest'} style={{ textDecoration: 'none', color: 'inherit' }}>
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginLeft: '.5rem' }}>
          <span>
            <Settings24Regular />
          </span>
          <span className="ms-fontWeight-semibold">
            Manage Interests
            <div>
              <span className="ms-fontWeight-regular ms-fontSize-12	" style={{ color: '#616161' }}>
                {appState.interests.size} interests
              </span>
            </div>
          </span>
        </div>
      </Link>
      <Divider style={{ padding: '0.4rem' }} appearance="subtle"></Divider>
      <Link to={'/manageGroup'} style={{ textDecoration: 'none', color: 'inherit' }}>
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginLeft: '.5rem' }}>
          <span>
            <PeopleCommunity24Regular />
          </span>
          <span className="ms-fontWeight-semibold">
            Manage Groups
            <div>
              <span className="ms-fontWeight-regular ms-fontSize-12	" style={{ color: '#616161' }}>
                {appState.groups.size} groups
              </span>
            </div>
          </span>
        </div>
      </Link>
      <Divider style={{ padding: '0.4rem' }} appearance="subtle"></Divider>
      <div
        style={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
        }}
      >
        <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible style={{ width: '100%' }}>
          <AccordionItem value="2">
            <AccordionHeader
              size="large"
              icon={<PeopleQueue24Regular />}
              expandIconPosition="end"
              style={{ backgroundColor: openItems.includes('2') ? '#f5f5f5' : 'white' }}
            >
              <span className="ms-fontWeight-semibold">
                Meeting Attendees
                <div>
                  <span className="ms-fontWeight-regular ms-fontSize-12	" style={{ color: '#616161' }}>
                    minimum: {appState.adminSettings.meetingAttendeeCount.min} - maximum:{' '}
                    {appState.adminSettings.meetingAttendeeCount.max}
                  </span>
                </div>
              </span>
            </AccordionHeader>
            <AccordionPanel style={{ marginLeft: '2rem' }}>
              <MeetingAttendees
                minAttendees={appState.adminSettings.meetingAttendeeCount.min}
                maxAttendees={appState.adminSettings.meetingAttendeeCount.max}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  )
}
